<template>
  <section
    v-if="store.showLanding"
    @click="closeLanding"
    class="fixed top-0 left-0 bg-white flex flex-col items-center justify-between cursor-pointer z-20"
  >
    <div class="flex flex-1 w-full items-center justify-center">
      <div class="w-[90%] md:w-[30%] xl:w-[35%] overflow-hidden transition-all ease-smooth duration-smooth">
        <img class="object-contain aspect-square animate-fadeIn duration-1000" :src="cover.src" />
      </div>
    </div>
    <div class="flex flex-row gap-x-1 min-h-12.5 h-12.5 items-center justify-center animate-translateIn">
      <logo />
      <p class="tracking-tight">by Daniela Türkyilmaz</p>
    </div>
  </section>
</template>

<script setup>
import { useStore } from '~/store/index';

const store = useStore();

const cover = computed(() => {
  const artworks = store.artworks;
  const index = Math.floor(Math.random() * artworks.length);
  const artwork = artworks[index];
  return artwork.images.find((i) => i.type === 'cover');
});

const closeLanding = () => store.setLandingStatus(false);
</script>
