<template>
  <nav class="flex flex-row items-center justify-between w-full min-h-12.5 px-default gap-x-2 z-20">
    <!-- Logo -->
    <logo @click.native="setView('artworks')" />

    <!-- Menu -->
    <ul class="w-1/2 flex flex-row px-default-half gap-x-0.5">
      <li @click="setView('artworks')" :class="{ active: store.view === 'artworks' }">Artworks</li>
      <li>/</li>
      <li @click="setView('index')" :class="{ active: store.view === 'index' }">Index</li>
      <li>/</li>
      <li @click="setView('about')" :class="{ active: store.view === 'about' }">About</li>
    </ul>
  </nav>
</template>

<script setup>
import { useStore } from '~/store/index';
const store = useStore();

const setView = (view) => {
  store.setView(view);
};
</script>

<style scoped lang="scss">
ul {
  @apply text-neutral-400;
  & > li {
    &:nth-child(odd) {
      @apply cursor-pointer md:hover:text-rose-600;
    }
    &:nth-child(even) {
      @apply font-normal;
    }
    &.active {
      @apply text-black;
    }
  }
}
</style>
