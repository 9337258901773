<template>
  <div class="flex-1 z-10 overflow-scroll -mb-4">
    <div class="grid grid-cols-1 md:grid-cols-4 gap-default px-default">
      <div
        class="relative md:aspect-square md:bg-[rgb(245,245,245)] md:flex md:items-center md:justify-center cursor-pointer last:mb-default"
        v-for="(artwork, i) in store.artworks"
        :key="i"
        @click="setArtwork(artwork)"
      >
        <img
          :class="
            $cl('w-full md:shadow-artwork', {
              'md:w-4/5 ': artwork.direction === 'square',
              'md:w-8/12 ': artwork.direction === 'vertical',
              'md:w-11/12 ': artwork.direction === 'horizontal',
              'w-4/12 mx-auto md:mx-0 md:w-1/4': artwork.direction === 'small'
            })
          "
          :src="getArtworkCover(artwork).src"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '~/store/index';

const store = useStore();

const getArtworkCover = (artwork) => artwork.images.find((i) => i.type === 'cover');

const setArtwork = (artwork) => {
  if (store.view === 'about') {
    store.setView('artworks');
  } else {
    store.setSelected(artwork);
  }
};
</script>
