<template>
  <main class="flex flex-row">
    <!-- Root -->
    <div
      :class="
        $cl('w-full flex flex-col justify-between transition-all ease-smooth duration-smooth z-10 bg-white', {
          '-translate-x-full md:translate-x-0 md:w-1/2 ': store.view === 'about'
        })
      "
    >
      <!-- Main -->
      <section class="flex flex-col">
        <!-- Navbar -->
        <navbar />
        <artworksIndex />
        <artworksGallery />
      </section>
    </div>

    <!-- About -->
    <about />
    <!-- Landing -->
    <landing />
  </main>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useStore } from '~/store/index';

const store = useStore();
const router = useRouter();

const { canNavigate } = storeToRefs(store);

watch(canNavigate, () => {
  if (canNavigate.value) {
    router.push(`/${store.selected.id}-${store.selected.title}`);
    store.setSelected(null);
  }
});
</script>
