<template>
  <div
    :class="
      $cl(
        'fixed top-0 left-full w-full md:w-1/2 h-full transition-transform ease-smooth duration-smooth overflow-hidden flex flex-col px-default md:pl-0',
        {
          '-translate-x-full': store.view === 'about'
        }
      )
    "
  >
    <!-- Navbar -->
    <div class="w-full min-h-12.5 h-12.5 flex items-center justify-end">
      <p class="cursor-pointer" @click="store.setView('artworks')">Close</p>
    </div>
    <!-- About -->
    <div class="flex flex-col overflow-auto pb-default w-full h-full">
      <!-- <img
        class="w-full aspect-video mb-default object-cover"
        src="https://images.thewest.com.au/publication/C-7290670/c6557fad9b034168344ed4f9420467b2dac08866-16x9-x0y0w1280h720.jpg"
      /> -->

      <!-- Bio -->
      <div class="grid grid-cols-1 gap-y-6">
        <div class="flex flex-col bio gap-y-default-quadruple md:gap-y-0">
          <p v-html="information.about.it.bio" />
          <p class="text-neutral-500" v-html="information.about.en.bio" />
        </div>

        <!-- About Me -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-default-double gap-y-default-triple bio">
          <div>
            <p class="text-xs">Il processo</p>
            <p class="paragraph" v-html="information.about.it.more" />
          </div>
          <div class="text-neutral-500">
            <p class="text-xs">The process</p>
            <p class="paragraph" v-html="information.about.en.more" />
          </div>
        </div>

        <!-- Social -->
        <div>
          <p class="text-xs">Further information</p>
          <p class="paragraph">
            To learn more about my work, please contact me via
            <span class="inline-block hover:text-rose-600">
              <a class="" href="mailto:kydotu@bluewin.ch" target="_parent" rel="noopener noreferrer">Email</a>
            </span>
            or visit my
            <span class="inline-block hover:text-rose-600">
              <a class="" href="https://www.instagram.com/materica_mente/" target="_parent" rel="noopener noreferrer">Instagram</a>
            </span>
            profile. I would be delighted to provide further information and discuss potential collaborations.
          </p>
        </div>

        <p class="text-xs teneutext-neutral-500 mt-5">
          Design and Code:
          <a class="text-xs" href="https://www.kerem.ch" target="_blank" rel="noopener noreferrer"> Kerem Türkyilmaz </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '~/store/index';
const store = useStore();
const information = ref(store.information);
</script>

<style scoped lang="scss">
.bio {
  &:first-child {
    p {
      @apply text-xl leading-[105%] md:leading-[118%];
    }
  }
  p {
    letter-spacing: -0.05px;
    word-spacing: -1.4px;
  }
}

.paragraph {
  @apply text-base leading-[110%] md:leading-[120%];
  a {
    @apply text-red-600;
  }
}
</style>
