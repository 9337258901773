<template>
  <div
    :class="
      $cl('relative h-0 transition-height ease-smooth duration-smooth overflow-hidden px-default', {
        'h-4/6 md:h-1/2': store.view === 'index'
      })
    "
    @transitionend="onTransitionend"
  >
    <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-default gap-y-default-double md:gap-y-default-triple pb-10 overflow-auto max-h-full">
      <li class="cursor-pointer" v-for="(artwork, i) in store.artworks" :key="i">
        <artwork-info type="index" :artwork="artwork" :id="i" @click.native="store.setSelected(artwork)" />
      </li>
    </ul>
    <div class="absolute left-0 bottom-0 w-full h-15 bg-gradient-to-t from-white to-transparent" />
  </div>
</template>

<script setup>
import { useStore } from '~/store/index';

const store = useStore();

const onTransitionend = () => {
  store.setIndexStatus(!store.indexIsOpen);
};
</script>
